<template>
  <div class="vg_wrapper">
    <add-header @submit="submit('sourForm')" @cancel="cancel" :stateFlag="true"></add-header>
    <el-form ref="sourForm" :model="sourForm" :rules="rules" label-width="120px" size="mini">
      <el-row class="vg_mb_16">
        <el-col :md="8">
          <el-form-item label="申请单号" prop="sour_no">
            <el-input
              maxlength="10"
              v-model="sourForm.sour_no"
              @input="sourForm.sour_no = helper.keepEngNum2(sourForm.sour_no)"
              placeholder="请填写申请单号（只能输入英语字母和数字加-加_）"
              show-word-limit
            >
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="公司编号" prop="cptt_id">
            <el-select v-model="sourForm.cptt_id" placeholder="请选择公司编号" @change="cpttChange" size="small" clearable>
              <el-option v-for="item in cpttList" :key="item.cptt_id" :label="item.cptt_no" :value="item.cptt_id"> </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="总金额" prop="sour_price">
            <el-input
              class="souPrice"
              :disabled="true"
              maxlength="10"
              v-model="sourForm.sour_price"
              @blur="sourForm.sour_price = calcPriceOne(sourForm.sour_price, 4, 1000000)"
              @input="sourForm.sour_price = helper.keepTNum1(sourForm.sour_price)"
              placeholder="将根据额外金额和物料价格自动生成"
              show-word-limit
            >
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="vg_mb_16">
        <el-col :md="8">
          <el-form-item label="交货地址" prop="cptt_address">
            <el-input maxlength="50" v-model="sourForm.cptt_address" type="textarea" rows="1" placeholder="请填写地址" show-word-limit>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="交货时间" prop="sour_deli_time">
            <el-date-picker v-model="sourForm.sour_deli_time" type="date" placeholder="请选择时间日期"> </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="额外金额" prop="extra_price">
            <el-input
              v-model="sourForm.extra_price"
              placeholder="请输入额外金额"
              maxlength="9"
              show-word-limit
              @change="extraPrice"
              @blur="sourForm.extra_price = helper.haveFour(sourForm.extra_price)"
              @input="sourForm.extra_price = helper.keepTNum1(sourForm.extra_price)"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :md="8">
          <el-form-item label="备注">
            <el-input maxlength="200" v-model="sourForm.remark" type="textarea" rows="1" placeholder="请填写备注" show-word-limit>
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <div class="vd_solidDiv"></div>
      <div>
        <div class="vd_but">
          <!-- <el-button type="primary" plain size="small" @click="addRow()">新增</el-button> -->
          <el-button type="success" plain size="small" @click="smplImport()">导入</el-button>
          <el-button type="danger" plain size="small" @click="delBom()">删除</el-button>
        </div>
        <SourSmplList
          v-if="smplFlag1"
          @sourPrice="sourPrice"
          :mtrlTypeGroupOpt="mtrlTypeGroupOpt"
          :sourForm="sourForm"
          :stffType="stffType"
          @handleSelectionChange="handleSelectionChange"
        ></SourSmplList>
      </div>
      <el-dialog :title="title" :visible.sync="smplFlag" width="70%">
        <SourSmplResult @falseBom="falseBom" @childSmpl="childSmpl" @childSmplChanel="childSmplChanel" ref="SmplEditIn"></SourSmplResult>
      </el-dialog>
      <el-row class="vg_mt_16 vd_bortop">
        <el-col>
          <inputUser :isCookie="true" ref="userData"></inputUser>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import { get, post } from '@api/request';
import { sourAPI } from '@api/modules/sour';
import { stffAPI } from '@api/modules/staff';
import { cpttAPI } from '@api/modules/cptt';
import { optnAPI } from '@api/modules/optn';
import addHeader from '@/views/component/addHeader';
import inputUser from '@/views/component/inputUser';
import SourSmplList from '@/views/PurchaseManagement/SourManage/TabChild/Componet/SourSmplList.vue';
import SourSmplResult from '@/views/PurchaseManagement/SourManage/TabChild/Componet/SourSmplResult.vue';

export default {
  name: 'sourEditMain',
  components: {
    addHeader,
    inputUser,
    SourSmplList,
    SourSmplResult
  },
  data() {
    var validatePrice = (rule, value, callback) => {
      if (value > 999999.9999) {
        callback(new Error('总金额不能大于999999.9999'));
      }
      callback();
    };
    return {
      rules: {
        sour_price: [{ validator: validatePrice }],
        sour_no: [{ required: true, trigger: 'blur', message: ' ' }],
        cptt_id: [{ required: true, trigger: 'change', message: ' ' }],
        cptt_address: [{ required: true, trigger: 'change', message: ' ' }],
        sour_deli_time: [{ required: true, trigger: 'change', message: ' ' }],
        extra_price: [
          {
            pattern: /^\d{0,4}(\.\d{1,4})?$/,
            message: '最大只能输入9999.9999并且只能输入数字且最多4位小数!'
          }
        ]
      },
      sourForm: {
        sour_no: null,
        cptt_id: null,
        sour_price: null,
        cptt_address: null,
        sour_deli_time: null,
        sour_mtrl_list: [],
        extra_price: '',
        remark: ''
      },
      totalPrice: '',
      btn: {},
      stffForm: {},
      stffType: [],
      sourCopy: [],
      selectChangeList: [],
      mtrlTypeGroupOpt: [],
      cpttList: [],
      smplFlag: false,
      smplFlag1: true,
      title: '物料信息'
    };
  },
  created() {
    this.initData();
  },
  watch: {},
  methods: {
    initData() {
      this.getStffType();
      this.getCpttV1();
      this.getMtrlType();
    },
    //提交表单
    submit(formName) {
      this.$confirm('确认提交?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$refs[formName].validate(valid => {
            if (valid) {
              this.saveInfo();
            } else {
              console.log('error submit!');
              return false;
            }
          });
        })
        .catch(res => {
          let mg = '已取消保存';
          let tp = 'warning';
          this.$message({ message: mg, type: tp });
        });
    },
    // 保存
    saveInfo() {
      const staffForm = this.$refs.userData.getData();
      let sourForm = {};
      sourForm = Object.assign(sourForm, this.sourForm, staffForm);
      sourForm.sour_deli_time = parseInt(Number(sourForm.sour_deli_time) / 1000);

      if (sourForm.sour_price > 999999.9999) {
        return this.$message.error('总金额不能大于999999.9999');
      }

      post(sourAPI.addSour, sourForm).then(res => {
        if (res.data.code === 0) {
          this.$message({
            type: 'success',
            message: '保存成功'
          });
          const permId = this.$route.query.perm_id;
          this.jump(`/sour_edit?perm_id=${permId}&form_id=${res.data.data.form_id}`);
          this.resetForm('sourForm');
          this.sourForm.sour_mtrl_list = [];
        } else if (res.data.code === 7) {
          this.$message({
            type: 'error',
            message: '采购申请单重复'
          });
        } else {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        }
      });
    },
    //取消
    cancel() {
      this.$confirm('确定取消新增?', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      })
        .then(() => {
          this.resetForm('sourForm');
          this.sourForm.sour_mtrl_list = [];
          this.$router.go(-1);
          this.$message({
            type: 'info',
            message: '已取消新增!'
          });
        })
        .catch(res => {});
    },
    // 计算价格
    calcPriceOne(num, posi = 0, str) {
      let num1 = 0;
      let fre = null;
      if (posi % 3 === 1) {
        fre = Math.pow(10, posi + 1);
      } else {
        fre = Math.pow(10, posi);
      }
      num1 = Math.floor(num * fre) / fre;
      if (isNaN(num1)) {
        return 0;
      } else {
        if (num1 >= str) {
          this.$message({
            type: 'error',
            message: '总金额不能大于1000000'
          });
          return;
        } else {
          let value = num1;
          value = value.toString();
          if (value) {
            let ind = value.toString().indexOf('.');
            if (ind < 0) {
              return Number(value + '.0000');
            } else {
              if (value.length - 1 - ind > 4) {
                return Number(value.splice(ind + 5));
              } else if (value.length - 1 - ind < 4) {
                let index = 4 - (value.length - 1 - ind);
                for (let i = 0; i < index; i++) {
                  value = value + '0';
                }
                return Number(value);
              } else {
                return Number(value);
              }
            }
          }
        }
      }
    },
    // 刷新按钮
    buttonRefresh() {
      this.initData();
    },
    // cptt变化选择地址编号
    cpttChange(val) {
      let temp = {};
      temp = this.cpttList.filter(item => item.cptt_id === val);
      if (temp.length === 1) {
        this.sourForm.cptt_address = temp[0].cptt_address;
        this.sourForm.cptt_no = temp[0].cptt_no;
      }
    },
    // 打开弹框
    smplImport() {
      this.smplFlag = true;
    },
    // 导入传值
    childSmpl(val) {
      // this.smplFlag1 = false;
      let value = val;
      value = value.filter(item => this.sourForm.sour_mtrl_list.every(item1 => item.mtrl_id !== item1.mtrl_id));
      for (let i = 0; i < value.length; i++) {
        if (value[i].mtrl_width || value[i].mtrl_width === 0) {
          value[i].mtrl_width = this.helper.haveFour(value[i].mtrl_width);
        }
        if (value[i].mtrl_prod_price || value[i].mtrl_prod_price === 0) {
          value[i].mtrl_prod_price = this.helper.haveFour(value[i].mtrl_prod_price);
        }
        value[i].disabledFlag = true;
      }
      this.sourForm.sour_mtrl_list = this.sourForm.sour_mtrl_list.concat(value);
      // this.$nextTick(()=>{
      //   this.smplFlag1 = true
      // })
      this.$refs.SmplEditIn.clear();
      this.smplFlag = false;
    },
    // 取消选择
    childSmplChanel() {
      this.smplFlag = false;
    },
    falseBom() {
      this.childSmplChanel();
    },
    // 样品list清单多选删除
    delBom() {
      if (this.selectChangeList.length > 0) {
        this.sourForm.sour_mtrl_list = this.sourForm.sour_mtrl_list.filter(item =>
          this.selectChangeList.every(item1 => item.mtrl_id !== item1.mtrl_id)
        );
        let price = 0.0;
        let mtrlList = this.sourForm.sour_mtrl_list;
        for (let i = 0; i < mtrlList.length; i++) {
          if (mtrlList[i].mtrl_num) {
            price += Number(mtrlList[i].mtrl_retail_price) * Number(mtrlList[i].mtrl_num);
          }
        }
        let price1 = parseInt(Number(price) * 1000000);
        let price2 = parseInt(Number(this.sourForm.extra_price) * 1000000);
        this.sourForm.sour_price = this.helper.haveFour((Number(price1) + Number(price2)) / 1000000);
        if (this.sourForm.sour_mtrl_list.length === 0) {
          this.totalPrice = 0;
        }
      } else {
        this.$message({
          message: '至少选择一条信息',
          type: 'warning'
        });
      }
    },
    // 样品单list选择
    handleSelectionChange(val) {
      this.selectChangeList = val;
    },
    // 更改价格
    sourPrice(val) {
      // if (val < 1000000) {
      let price1 = parseInt(Number(val) * 1000000);
      let price2 = parseInt(Number(this.sourForm.extra_price) * 1000000);
      this.sourForm.sour_price = this.helper.haveFour((Number(price1) + Number(price2)) / 1000000);
      this.totalPrice = JSON.parse(JSON.stringify(val));
      // } else {
      //   this.sourForm.sour_price = null;
      //   setTimeout(() => {
      //     this.$message({
      //       type: "error",
      //       message: "总金额不能大于1000000",
      //     });
      //   }, 1000);
      // }
    },
    // 获取委托人
    getStffType() {
      get(stffAPI.getAllStffsV1)
        .then(res => {
          if (res.data.code === 0) {
            this.stffType = res.data.data;
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    //表单重置
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.sourForm.remark = null;
    },
    // 获取公司信息
    getCpttV1() {
      get(cpttAPI.getAllCpttsV2)
        .then(res => {
          if (res.data.code === 0) {
            this.cpttList = res.data.data;
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    // 获取单位
    getMtrlType() {
      get(optnAPI.getAllContent, { perm_id: 10005 })
        .then(res => {
          if (res.data.code === 0) {
            this.mtrlTypeGroupOpt = res.data.data;
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    extraPrice() {
      if (this.sourForm.extra_price == 0) {
        this.sourForm.sour_price = this.helper.haveFour(this.totalPrice);
        return;
      }
      let price1 = parseInt(Number(this.totalPrice) * 1000000);
      let price2 = parseInt(Number(this.sourForm.extra_price) * 1000000);
      this.sourForm.sour_price = this.helper.haveFour((Number(price1) + Number(price2)) / 1000000);
    },

    //新增采购
    addRow() {
      let item = {
        supp_name: null,
        mtrl_no: null,
        mtrl_name: null,
        mtrl_type: null,
        mtrl_spec: null,
        mtrl_color: null,
        mtrl_width: null,
        mtrl_unit: null,
        mtrl_prod_price: null,
        mtrl_retail_price: null,
        mtrl_num: null,
        disabledFlag: false,
        addFlag: true
      };
      this.sourForm.sour_mtrl_list.push(item);
    }
  }
};
</script>

<style scoped lang="scss">
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader ::v-deep .el-upload--text {
  width: 136px;
  height: 136px;
}
.avatar-uploader .el-upload:hover {
  border-color: $color-primary;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: $color-uploader-icon;
  width: 136px;
  height: 136px;
  line-height: 136px;
  text-align: center;
}
.avatar {
  width: 136px;
  height: 136px;
  display: block;
}
.vd_bortop {
  border-top: #dcdfe6 solid 1px;
  padding-top: 20px;
}
.vd_mrt1 {
  margin-top: 1px;
}
.vd_edhea {
  position: relative;
}
.vd_export {
  position: absolute;
  top: 0;
  left: 65px;
}
.vd_solidDiv {
  height: 1px;
  border: none;
  background-color: #dcdfe6;
  margin: 10px 0 20px 0;
}
.vd_but {
  margin-bottom: 10px;
}
.el-dialog__title {
  font-size: 8px;
}
.el-row {
  margin-top: 2px;
}
::v-deep .el-form-item__error {
  font-size: 14px;
}
</style>
